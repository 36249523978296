import * as types from './types';
import configureStore from '../../../store/configureStore';

export function login(userName, password) {    
	return { type: types.LOGIN_REQUEST, payload: { userName, password } };
}
export const logoutUser = () => { 
    const store = configureStore(); 
    localStorage.removeItem('USER_INFO'); 
    store.persistor.purge();
    return { type: types.LOGOUT_SUCCESS };
};

export const logout = () => {
  
    const payload = {
        userInfo: {
            isLoggedIn: false,
            firstname: '',
            lastname:'',
            email:'',
            username:'',
            allInfo:{}
        },
        error: [],
        isLoggedIn: false,
        isError: false,
        isPending: false
    }

    return { type: types.LOGOUT_REQUEST, payload };
};