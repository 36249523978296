import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
import { push } from 'react-router-redux';

export function* createcode({ payload }) {
    try {
        
        debugger
        yield put({ type: types.CODE_LODING });
        let result;
        result = yield call(api.createcode, payload.userName, payload.password);  
          
        if(result.status == 200) {
          if(result.data.status){
            const userInfo = {                
                isCodesend: result.data.status,
                message:result.data.msg
              
            };     
            yield put({ type: types.CODE_SUCCESS, payload: {userInfo} });
          }
          else{
            yield put({ type: types.CODE_FAILURE, payload: result.data.msg }); 
          }
        }else {
          
           
            yield put({ type: types.CODE_FAILURE, payload: result.data.error });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        // console.log('c err', err);

    }
}

// export function* resetpassword({ payload }) {
//     try {
//       console.log(payload)
//         yield put({ type: types.FORGOT_REQUEST });
//         let result;
//         result = yield call(api.resetpassword, payload.userName, payload.password, payload.confirmCode);  
//         console.log(result)     
//         if(result.status == 200) {
         
//             const userInfo = {                
//                 isChange: true,
              
//             };
           
                     
//             yield put({ type: types.FORGOT_SUCCESS, payload: {userInfo} });

            

//         } else if(result.isAxiosError){            
//             yield put({ type: types.FORGOT_FAILURE, payload: result.response.data }); 

//         }else {
          
           
//             yield put({ type: types.FORGOT_FAILURE, payload: result.data.error });
//         }
        
//     } catch (err) {
      

//     }
// }

function* createcodeSaga(data) {    
    yield takeLatest(types.CODE_REQUEST, createcode);    
}


export default createcodeSaga;
