import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/images/logo/cp_logo.png'
import './Common.scss'
const HeaderPublic = () => {
  let timeout
  let scroll = 0
  useEffect(() => {
    window.onscroll = () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        if (scroll >= window.scrollY && window.scrollY > 10) {
          document.getElementById('publicheader').classList.add('publicsticky')
        } else {
          document.getElementById('publicheader').classList.remove('publicsticky')
        }

        scroll = window.scrollY
      }, 10)
    }
  }, [])
  return (
    <header id='publicheader' className='CP_header'>
      <div className='logo'>
        <Link to='/home'><img src={Logo} alt='logo' /></Link>
      </div>
      <div className='buttonBlock'>
        <Link to='/register' className='btn btn_outline'>Create an Account</Link>
        <Link to='/login' className='btn btn_primary'>Sign In</Link>
      </div>
    </header>
  )
}

export default HeaderPublic