import React from 'react'
import './Demorequest.scss'
import FooterPublic from './Common/FooterPublic'
import HeaderPublic from './Common/HeaderPublic'
import demoreg from '../../assets/images/public/demoreg.svg'
const Demorequest = () => {
    return (
        <div className='Demorequest'>
            <HeaderPublic />
            <div className='qtfy_hero_container row px-lg-5 px-md-2'>
                <div className='col-md-12'>
                    <div className='qtfy_hero_content_panel d-flex align-items-center justify-content-center'>
                        <div className='demoReg_hero_panel'>
                            <h1 className='demoreg_title_content'>We will <span className='demoreg_title'>get in touch with you</span> </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='qtfy_public_body'>
                <div className='container py-4'>
                    <div className='row align-items-center mt-5'>
                        <div className='col-md-6 d-lg-block d-none'>
                            <div className='regdemo_imagePanel'>
                                <img src={demoreg} alt='demoimage' className='w-100' />
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <div className='demoReg_form_panel'>
                                <h3 className='demoReg_form_panel_title'>Submit your contact information, we will reach out you as soon as possible.</h3>
                                <form className='mt-2 row g-3'>
                                    <div className='col-md-6'>
                                        <div className='op_input_block my-1'>
                                            <label className='form-label'>First Name</label>
                                            <input className='form-control' type='text'   />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='op_input_block my-1'>
                                            <label className='form-label'>Last Name</label>
                                            <input className='form-control' type='text' />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='op_input_block my-1'>
                                            <label className='form-label'>Email ID</label>
                                            <input className='form-control' type='email'  />
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='op_input_block my-1'>
                                            <label className='form-label'>Specific needs or questions?</label>
                                            <input className='form-control' type='text'  />
                                        </div>
                                    </div>
                                    <div className='col-md-12 text-center'>
                                        <button type="button" className='btn btn_primary rounded-pill mt-3 w-50'>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPublic />
        </div>
    )
}

export default Demorequest