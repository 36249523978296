import { combineReducers } from 'redux';
import user from '../ducks/gateway/login/reducers';
import signup from '../ducks/gateway/signup/reducers';
import createcode from '../ducks/gateway/forgotpassword/reducers';
import reset from '../ducks/gateway/forgotpassword/resetreducers';

import menu from '../ducks/gateway/menus/reducers';
import defaultreducder from './default/reducer'

const rootReducer = combineReducers({ 
    user,menu,signup,createcode,reset,defaultreducder 
});

export default rootReducer;