import React from 'react'
import './Common.scss'
import footLogo from '../../../assets/images/public/footLogo.png'
import { Link } from 'react-router-dom'
const FooterPublic = () => {
    return (
        <footer className='qtfy_footer'>
            <div className='row px-3 g-3 align-items-center'>
                <div className='col-lg-6 col-md'>
                    <p className='m-0 copy_rights text-lg-start text-center'>© 2023 Cyber Predictor., All rights reserved</p>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <ul className='d-flex p-0 justify-content-center'>
                        <li className='footer_page_link'>
                            <Link to='/terms'>Terms of use</Link></li>
                        <li className='footer_page_link'>|</li>
                        <li className='footer_page_link'>
                            <Link to='/privacy'>Privacy policy</Link>
                        </li>
                    </ul>
                </div>
                <div className='col-lg-2 col-md-6'>
                    <div className='d-flex gap-1 align-items-center justify-content-lg-end justify-content-center'>
                        <p className='poweredBY mb-0'>Powered by</p>
                        <img src={footLogo} alt='footer_logo' className='footer_logo' />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterPublic