import React from 'react'
import { Switch, Route } from 'react-router-dom';
import Home from '../components/Publicpages/Home';
import Privacy from '../components/Publicpages/Privacy';
import Terms from '../components/Publicpages/Terms';
import Demorequest from '../components/Publicpages/Demorequest';
const CPpublicpage = () => {
    return (
        <>
            <Switch>
                <Route key="home" path="(/home)" exact={true} component={Home} />
                <Route key="privacy" path="(/privacy)" exact={true} component={Privacy} />
                <Route key="terms" path="(/terms)" exact={true} component={Terms} />
                <Route key="demorequest" path="(/demorequest)" exact={true} component={Demorequest} />
            </Switch>
        </>
    )
}

export default CPpublicpage