import axios from './fetchInstace';

export function authApi(url, payload) {
  //https://dtlehta1thuw9.cloudfront.net/auth/api/auth
//   const AUTH_TOKEN = JSON.parse(localStorage.getItem('USER_INFO'));
//   if (AUTH_TOKEN) {
//     axios.defaults.headers.common['Authorization'] = `${AUTH_TOKEN.allInfo.accessToken}`;
//   }
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_AUTH_URL + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => {
      return { ...Error }
    }
  );
}