import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
// import { push } from 'react-router-redux';
import configureStore from '../../../store/configureStore';

function* login({ payload }) {
    try {        
        yield put({ type: types.LOGIN_LODING });
        let result;
        result = yield call(api.login, payload.userName, payload.password);         
        if(result.status == 200) {    
           
                const userInfo = {                
                    isLoggedIn: true,
                    //firstname: result.data.firstName,
                    firstname: result.data.details.login_name,
                    lastname:result.data.lastName,
                    email:result.data.details.userEmail,
                    username:result.data.userName,
                    userId:result.data.userId,
                    allInfo:result.data
                };
                localStorage.setItem('USER_INFO', JSON.stringify(userInfo));            
                yield put({ type: types.LOGIN_SUCCESS, payload: {userInfo} });
         

        } else if(result.response.status == 500){        
              
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message }); 

        }else {
            //console.log("Anand");
            console.log(result);
          console.log(result.response.data.errors[0].message);
            const usererror = {
                dseverity: 'error',
                dAlertTitle: 'Error',
                error: result.response.data.errors[0].message,
                isError: true
            }
    
           // yield put({ type: constants.SET_SERVER_ERROR, payload: usererror });
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.errors[0].message });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        // console.log('c err', err);

    }
}

function* logoutUser({ payload }) {
    try {  
             
        localStorage.removeItem('USER_INFO'); 
        const store = configureStore();
        store.persistor.purge();
        yield put({ type: types.LOGOUT_SUCCESS, payload });
        return { type: types.LOGOUT_SUCCESS };
    } catch (err) {
        yield put({ type: types.LOGOUT_FAILURE, payload: "error" });
    }
}

function* loginSaga(data) {    
    yield takeLatest(types.LOGIN_REQUEST, login);
    yield takeLatest(types.LOGOUT_REQUEST, logoutUser);
}

export default loginSaga;
