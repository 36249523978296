import React from 'react'
import FooterPublic from './Common/FooterPublic'
import HeaderPublic from './Common/HeaderPublic'
import rightImage from '../../assets/images/public/hero_img.svg'
import aboutIMG from '../../assets/images/public/about_cp.png'
import keyFeatures from '../../assets/images/public/key_features.png'
import webVapt from '../../assets/images/public/webVapt.png'
import networkVapt from '../../assets/images/public/networkVapt.png'
import mobileVapt from '../../assets/images/public/mobileVapt.png'
import singlife from '../../assets/images/public/singlife.png'
import './Home.scss'
import { Link } from 'react-router-dom'
const Home = () => {
  return (
    <>
      <HeaderPublic />
      <div className='CP_public_home'>
        <div className='hero_section'>
          <div className='hero_section_wrapper'>
            <div className='hero_left'>
              <h1>Cyber Predictor – Providing visibility & Securing your environment</h1>
              <p>Protect your digital assets, users, and data with a modernized and multi-cloud platform.</p>
              {/* <Link to='/demorequest' className='btn btn_white d-inline-flex align-items-center justify-content-center'>Request a Demo</Link> */}
            </div>
            <div className='hero_right'>
              <div className='hero_right_image'>
                <img src={rightImage} alt='banner' className='w-100' />
              </div>
            </div>
          </div>
        </div>
        <section className='body_wrapper'>
          <div className='g-4 align-items-center justify-content-around px-3 py-3 row'>
            <div className='col-md-12 col-md-6 col-lg-5'>
              <div className='layer_image'>
                <img src={aboutIMG} alt='about' />
              </div>
            </div>
            <div className='col-md-12 col-md-6 col-lg-6'>
              <div className='layer_content'>
                <h1>About Cyber Predictor</h1>
                <p>Cyber Predictor offers realistic insights that affiliate to the you strategic objectives, and supports your cyber security operations by giving extensive and continual visibility into security exposures. Provides you with a comprehensive approach in protecting all your digital assets, users and data with a modernized multi-cloud platform security system.</p>
              </div>
            </div>
          </div>
          <div className='g-4 align-items-center justify-content-around px-3 py-3 my-4 row bg-white'>
            <div className='col-md-12 col-md-6 col-lg-5'>
              <div className='layer_content'>
                <h1>Key Features</h1>
                <ul className='list'>
                  <li>Versatile competences to provide visibility to all the security exposures</li>
                  <li>Providing detailed view of divergent, doubtful, and despiteful cyber acts.</li>
                  <li>Proactive and responsive recommendations to remediate threats</li>
                  <li>Safeguarding your data from any type of applications, gadgets, and networks exposures</li>
                  <li>Competent in integrating with integrate existing security systems and analytics. </li>
                  <li>Competent in integrating with your existing security systems and analytics tools</li>
                  <li>Dynamic in multi-cloud environment capabilities</li>
                </ul>
              </div>
            </div>
            <div className='col-md-12 col-md-6 col-lg-6 '>
              <div className='layer_image'>
                <img src={keyFeatures} alt='Ley Features' />
              </div>
            </div>
          </div>
          {/* <div className='px-5 py-3 row g-4'>
            <div className='layer_content'>
              <h1 className='my-1'>Security and its Benefits</h1>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='security_item'>
                <h3 className='security_heading'>Improves business/organizational knowledge</h3>
                <p>Keeps you conscious in business/organizational susceptibilities thus gives you a clear cut knowledge of where your business/organization needs to enhanced or enriched.</p>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='security_item'>
                <h3 className='security_heading'>Rules out data violations</h3>
                <p>Keeps you a step away from mammoth business losses and in maintaining your organizational reputation.</p>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='security_item'>
                <h3 className='security_heading'>Focuses on future assessments</h3>
                <p>Assesses business needs continually on cyber risk and updates you on future assessments ensuring repeatable developments.</p>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='security_item'>
                <h3 className='security_heading'>Rules out regulatory concerns</h3>
                <p>Keeps you a step head in maintaining your customer information/data thus helps you in complying with customers policies.</p>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='security_item'>
                <h3 className='security_heading'>Focuses on Cost Reduction</h3>
                <p>Categorizing probable threats and vulnerabilities, that leads in potentially preventing and reducing security incidents which helps in saving business, money, and damaging reputation.</p>
              </div>
            </div>
          </div> */}
          <div className='g-4 align-items-center justify-content-around px-3 py-3 row my-4 row bg-white'>
            <div className='col-md-12 col-md-6 col-lg-5'>
              <div className='layer_image'>
                <img src={webVapt} alt='webvapt' />
              </div>
            </div>
            <div className='col-md-12 col-md-6 col-lg-6'>
              <div className='layer_content'>
                <h1 className='mb-3'>Web VAPT</h1>
                <p>Cyber Predictor helps assess, identify, and address the security exposures you might have your web application platforms and applications. Our tool is an integrated platform for checking vulnerabilities using OWSAP standards and provide comprehensive assessment reports </p>
              </div>
            </div>
          </div>
          <div className='g-4 align-items-center justify-content-around px-3 py-3  row'>
            <div className='col-md-12 col-md-6 col-lg-5'>
              <div className='layer_content'>
                <h1 className='mb-3'>Network VAPT</h1>
                <p>Our Network VAPT also identifies and captures tenuous areas in your system, which will help to succeed all attributes of monitoring detecting, notifying, and troubleshooting. Our tool scans your network externally and internally and also scans your infrastructure deployed behind your firewall too to provide a comprehensive coverage </p>
              </div>
            </div>
            <div className='col-md-12 col-md-6 col-lg-6 '>
              <div className='layer_image'>
                <img src={networkVapt} alt='Ley Features' />
              </div>
            </div>
          </div>
          <div className='g-4 align-items-center justify-content-around px-3 py-3 row my-4 row bg-white'>
            <div className='col-md-12 col-md-6 col-lg-5'>
              <div className='layer_image'>
                <img src={mobileVapt} alt='mobileVapt' />
              </div>
            </div>
            <div className='col-md-12 col-md-6 col-lg-6'>
              <div className='layer_content'>
                <h1 className='mb-3'>Mobile VAPT</h1>
                <p>Cyber Predictor helps to identify exploitable vulnerabilities in your mobile application and ensures that it is secure enough to use. Coverage includes both iOS and Android based mobile applications and our tool is built with required framework to scan and assess the vulnerabilities according to the industry standards. </p>
              </div>
            </div>
          </div>
          <section className='functionality_layer'>
            <div className='text-center'>
              <h1 className='white_title'>How Cyber Predictor works?</h1>
            </div>
            <div className='functionality_row g-3'>
              <div className='card fun_card'>
                <div className='card_icon m-auto'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="49.344" height="61.68" viewBox="0 0 49.344 61.68">
                    <path id="description_FILL0_wght400_GRAD0_opsz48" d="M20.259,52.573H45.085V47.947H20.259Zm0-13.107H45.085V34.84H20.259ZM12.626,65.68a4.438,4.438,0,0,1-3.238-1.388A4.438,4.438,0,0,1,8,61.054V8.626A4.438,4.438,0,0,1,9.388,5.388,4.438,4.438,0,0,1,12.626,4H40.459L57.344,20.885V61.054a4.743,4.743,0,0,1-4.626,4.626Zm25.52-42.714V8.626H12.626V61.054H52.718V22.967ZM12.626,8.626v0Z" transform="translate(-8 -4)" />
                  </svg>
                </div>
                <h3>SCAN</h3>
                <p>Scan your platform and network and provide visibility into any potential security exposure .</p>
              </div>
              <div className='card fun_card'>
                <div className='card_icon m-auto'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="51.3" height="57" viewBox="0 0 51.3 57">
                    <path id="lan_FILL0_wght400_GRAD0_opsz48" d="M6,61V41.762h8.55v-11.4H29.513V23.237h-8.55V4H42.337V23.237h-8.55v7.125H48.75v11.4H57.3V61H35.925V41.762h8.55V34.637H18.825v7.125h8.55V61ZM25.237,18.962H38.063V8.275H25.237ZM10.275,56.725H23.1V46.037H10.275Zm29.925,0H53.025V46.037H40.2ZM31.65,18.962ZM23.1,46.037ZM40.2,46.037Z" transform="translate(-6 -4)" />
                  </svg>
                </div>
                <h3>REVIEW & ASSESS</h3>
                <p>Review exposures and provide assistance to assess the severity  of the issues.</p>
              </div>
              <div className='card fun_card'>
                <div className='card_icon m-auto'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="40.727" height="64" viewBox="0 0 40.727 64">
                    <path id="security_update_good_FILL0_wght400_GRAD0_opsz48" d="M27.6,42.727l-8.145-8.145,3.055-3.055L27.6,36.618,38.945,25.273,42,28.327ZM14.364,66a4.186,4.186,0,0,1-3.055-1.309A4.186,4.186,0,0,1,10,61.636V6.364a4.186,4.186,0,0,1,1.309-3.055A4.186,4.186,0,0,1,14.364,2h32a4.186,4.186,0,0,1,3.055,1.309,4.186,4.186,0,0,1,1.309,3.055V61.636A4.474,4.474,0,0,1,46.364,66Zm0-6.545v2.182h32V59.455Zm0-4.364h32V12.909h-32Zm0-46.545h32V6.364h-32Zm0,0v0Zm0,50.909v0Z" transform="translate(-10 -2)" />
                  </svg>
                </div>
                <h3>REMEDIATE</h3>
                <p>Assist to provide the remediation plans to resolve threats and issues</p>
              </div>
            </div>
          </section>
          {/* <section className='customers_sec'>
            <div className='layer_content text-center'>
              <h1 className='my-1'>Our Customers</h1>
            </div>
            <div className='client_listing py-3'>
              <div className='client_logo'>
                <img src={singlife} alt='singlife' />
              </div>
            </div>
          </section> */}
        </section>
      </div>
      <FooterPublic />
    </>
  )
}

export default Home