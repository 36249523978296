import React from 'react'
import deleteModal from '../../../assets/images/del.png';

const Timeout = ({closeDialog}) => { 
    return (
        <div className="custom_modalContainer">
            <div className="del_modal card" style={{ width: '40vw', boxShadow: 'none' }} >
                <div className="modal_header card-header m-0">
                    <div className="modal_header-title p-2">Session Timeout</div>
                    {/* <button className="close" onClick={delToggle}><i className="bi bi-x"></i></button> */}
                </div>
                <div className='modal_body card-body'>
                    <div className='text-center py-2'>
                        <img src={deleteModal} alt='imagedelete' />
                        <div className='deleteModalContent my-3'>Current Session is Expired. Login to Continue</div>
                        {/* <div className='deleteModalContentMessage my-3'>{customMessage}</div> */}
                    </div>

                    <div className='text-center my-2'>                   
                        <button className='btn btn-sm btn_primary mx-2 px-5' onClick={closeDialog}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timeout