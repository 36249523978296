import React from 'react'
import FooterPublic from './Common/FooterPublic'
import HeaderPublic from './Common/HeaderPublic'
import heroImage from '../../assets/images/public/img_2.png'
import './Privacy.scss'
const Privacy = () => {
    return (
        <div className='privacy'>
            <HeaderPublic />
            <div className='op_bdy_panel'>
                <div className='qtfy_hero_container row px-lg-5 px-md-2'>
                    <div className='col-md-6'>
                        <div className='qtfy_hero_content_panel'>
                            <h1 className='hero_title'>Privacy</h1>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='qtfy_hero_right'>
                            <img src={heroImage} alt='Image_right' />
                        </div>
                    </div>
                </div>
                <div className='qtfy_public_body'>
                    <div className='container py-3'>
                        <div className='row g-3'>
                            <div className='col-md-12'>
                                <h1 className='qtfy_pg_title'>1TX - Cyber predictor Privacy Policy</h1>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>
                                    This Privacy Policy (“Privacy Policy“) describes the policies and practices of 1TX - Cyber predictor  and its affiliates (“1TX - Cyber predictor“, “we” or “us“) and the choices you have in connection with the collection, use, and disclosure of information provided to us, collected by us or that may be generated by your use of our website and services (the “Service”). Please review carefully this Privacy Policy before making any use of the Service. Each use of the Service by you constitutes your consent to the collection, transfer, storage, disclosure, and other uses of your personal information as described in this Privacy Policy. IF YOU DO NOT AGREE TO ABIDE BY THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICE.
                                </p>
                                <p className='qtfy_paragraph mb-2'>This policy describes how 1TX - Cyber predictor  treats your information, not how other organizations treat your information. If you are using 1TX - Cyber predictor  in a workplace or on a device or account issued to you by your employer or another organization, that company or organization likely has its own policies regarding storage, access, modification, deletion, and retention of communications and content which may apply to your use of 1TX - Cyber predictor . Content that would otherwise be considered private to you or to a limited group of people may, in some cases, may be accessible by your network administrator. Please check with your employer about the policies it has in place regarding your communications and related content on 1TX - Cyber predictor  .</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>The privacy philosophy of 1TX - Cyber predictor  </div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>Your privacy is important to 1TX - Cyber predictor . We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. However, due to the nature of Internet communications and evolving technologies, unauthorized entry or use, hardware or software failure, and other factors, the security of user information may be compromised at any time. No method of transmission over the Internet, or method of electronic storage, is 100% secure. If you become aware of any security vulnerability or potential data breach, please contact us immediately, and we will take the appropriate measures to address such incident, as deemed necessary</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>What information do we collect</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>We collect personal information as well as non-personnel information as part of providing the Service; the manner of collection and the manner of use of each type of infarction is detailed below and we will not make any use of the information disclosed or collected in any other manner. It is important to note that the use of the information is required in order to provide the Service. As the Service may evolve and change from time to time so will the information we will collect use. We strongly advise you to consult this page frequently in order to make sure you are up to date with the most current policy then in effect the date of the last updated and then in effect policy is clearly marked at the top of the page.</p>
                                <p className='qtfy_paragraph mb-2'>Personal Information means information that identifies or could be used by us to identify an individual. Except as described in this Policy, we will not share give, sell, rent or loan any personal information to any third party. Personal Information that we collect may include, but is not limited to, your name, email address, affiliates, photo and your credentials with a designated third party website or services. When you submit or make available your Personal Information through the Service, you are giving your consent to the collection, use and disclosure of such information pursuant to this Policy. If you do not wish us to collect any Personal Information from you, please do not provide us with any such information. However if you will not provide us with the required information we may not be able to provide you with the information or services requested by you.</p>
                                <p className='qtfy_paragraph mb-2'>Personal information does not include Usage Data which we define as aggregated or anonymized information that we may collect about you or your group or category of services in which you are referenced or to which you belong, or a set of features or users which does not contain personally identifying information. Personal information also does not include any other information about you which does not identify you personally.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Collection and use of information</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>When you create an account with us in order to use the Service, you will be asked to provide certain Personal Information such as your name, e-mail as well as certain related information pertaining to your company, group or association. Except as explained in this Privacy Policy, we will not give such information to third parties without your approval. Here are the types of information we collect or receive:</p>
                                <p className='qtfy_paragraph mb-2'><span className='qtfy_paragraph_title'>User Data</span> User Data is all electronic data or information submitted by you through the Service, including all text, sound, software or image files that you provide to us through your use of the Service. We make no claim of ownershipin User Data. Used for: Except as provided in this Privacy Policy, we only use User Data to provide and enhance the Service, to support and administer you use of it and for other interaction with us (for example, through the “contact us” option or other email communications). We do not share User Data with advertisers, or with anybody else except in the limited circumstances described below.</p>
                                <p className='qtfy_paragraph mb-2'><span className='qtfy_paragraph_title'>Account Information. </span> Account information includes phone number, you name, your company’s name, affiliation, title, location, and other contact information that you voluntarily choose to share with us beyond the required registration information and which we may collect through your use of the Service. Used for: Account Information is used to enable you to communicate with other users of the Service. Account Information you provide as part of your account will be available to other users in the Service based on the manner and settings you choose in the use of the Service. The Settings may be set by you and changed from time to time using the Settings function in the Service.</p>
                                <p className='qtfy_paragraph mb-2'><span className='qtfy_paragraph_title'>Billing Information. </span>Depending on the services you choose to utilize, you may be required to provide payment information. Used for: We do not store, retain, or use your billing information for any purpose. We use accredited well known and reputable third party service providers for the processing of payment. Please consult the third party processing service provider’s terms of use and privacy policy or ensure you are familiar with them prior to choosing the appropriate method of payment. We expressly disclaim and shall not have any liability for any use of any information relating to the payment process or the information collected in that process.</p>
                                <p className='qtfy_paragraph mb-2'><span className='qtfy_paragraph_title'>Usage Data.</span>We may use statistical data, analytics, trends and usage information derived from your use of the Service. Used for: Some of the ways in which we use the Usage Data include operating, improving and personalizing the Service and our offerings, as well as other products and services. We may monitor and analyze Usage Data for technical administration; to increase the Service functionality and user-friendliness; to better tailor it to your needs; and to generate and derive useful data and information concerning the interests, characteristics and website use behavior of our users. We may share Usage Data with third parties, including our partners and service providers, for various purposes including but not limited our own commercial benefit. If you are using the Service as a part of the organization or company we may provide Usage Data to such organization or company.</p>
                                <p className='qtfy_paragraph mb-2'><span className='qtfy_paragraph_title'>Support Data.</span>Support Data is the information we collect when you submit a support request or run an automated troubleshooter, including information about hardware, software, and other details related to the support incident, such as: contact or authentication information, information about the condition of the computer, network system and the application when the fault occurred and during diagnostics, system and registry data about software installations and hardware configurations, and error-tracking files. Used for: We use Support Data in the same way as we use Usage Data as described in this Privacy Policy.</p>
                                <p className='qtfy_paragraph mb-2'><span className='qtfy_paragraph_title'>Log Data.</span>Our servers automatically collect data about your Internet Protocol address (IP address) the browser sends when you visit us. We may also collect such other information about you which does not identify you personally, such as the type of browser and operating system you use; your Internet protocol addresses (including any host name or geographic information associated with these addresses); and information related to your computer or device system configuration or your web usage activity (including the web page the you were visiting before arriving to our Service). Used for: We may collect Log Data and employ “cookie” technology, clear gifs (also known as web beacons), and log file information to purposes such as: (a) collect website users’ usage data to enable easy access to the Service and otherwise improve the Service; (b) provide custom, personalized content and information; (c) monitor the effectiveness of the Service; (d) monitor aggregate metrics such as total number of visitors and pages viewed; (e) track your entries and submissions to the Service; and (f) generate “analytics” data for our own use.</p>
                                <p className='qtfy_paragraph mb-2'>A cookie is a small data file, which often includes an anonymous unique identifier, which is transferred to your computer’s hard disk to facilitate your computer’s future access to any given website. We may use both “session” cookies and “persistent” cookies. Persistent cookies remain on your computer until you or your browser delete them or until they expire. Persistent cookies are mainly utilized to save your user ID and login password to facilitate subsequent visits to the Service. Session-based cookies last only while your browser is open and are automatically deleted when you close your browser. When you visit our Service, our computer may ask your computer for permission to employ the use of a session or persistent cookie. Our Service will then send a cookie to your browser if your browser’s preferences allow it, but (to protect your privacy) your browser only permits our Service to access the cookies it has already sent to you and not the cookies sent to you by other websites. Please note that most Internet browsers automatically accept cookies. You should change your browser settings if you do not wish to accept cookies from us on your computer. However, refusing a cookie may, in some cases, preclude you from using, or negatively impact the display or function of our Service or certain areas or features of our Service. Please note that we do not currently recognize or respond to browser-initiated Do Not Track signals as there is no consistent industry standard for compliance.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>How we share your personal information</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>Depending on the way you choose to utilize the Service, your information may be shared with other users of the Services. 1TX strives to dramatically streamline pilot processes by facilitating proof of concept demonstrations between users, both start-ups and enterprises. In each interaction with other users, your information may be shared depending on the nature of communication you establish with such other users through the Service.</p>
                                <p className='qtfy_paragraph mb-2'>We also disclose Personal Information to those of our employees and our service providers who: (a) need to know this information in order to process it on our behalf, or to provide services offered through or relating to the Services; and (b) have agreed not to disclose it to others.</p>
                                <p className='qtfy_paragraph mb-2'>We may also disclose your Personal Information to third parties (a) to comply with any applicable law, regulation, legal process (including any court order) or governmental request, (b) to perform certain administrative functions consistent with our Privacy Policy on our behalf, (c) to protect our property and rights or those of a third party, (d) to prevent or stop any activity we may consider to pose a risk of being illegal, unethical, inappropriate or legally actionable, and (e) in connection with enforcement of this Privacy Policy and the Terms of Use.</p>
                                <p className='qtfy_paragraph mb-2'>We occasionally contract with other companies to provide services (such as data management, web analytics services and technical infrastructure services) on our behalf. We may provide these companies with access to your Personal Information where necessary for their engagement. These companies are required to maintain the confidentiality of your Personal Information and are prohibited from using it for any purpose other than that for which they are engaged by us.</p>
                                <p className='qtfy_paragraph mb-2'>We may target ads to you, based on the information you included in your account. We reserve the right to target ads to you in a variety of manners through various ad networks and ad exchanges, such as: using web beacons, pixels, ad tags, cookies; advertising based on user-provided account information and categories (for example, ad targeted to users in a certain field of practice or category); Personal Information inferred from your account (for example, using job titles to infer age, industry, seniority; or names to infer gender); your Usage Data; providing Information from advertising partners which we use to help deliver ads which are more relevant to you.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>How you can protect your privacy</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>Your personal account for use of our Services is password-protected for your privacy and security. At all times, you must protect your password appropriately and limit access to your computer to prevent unauthorized access to your account and Personal Information.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Opt-in options and unsubscribing</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>You may be asked to allow us to use your email address in order to present you, from time to time, via email communication, with news and updates about our Services, as well as other marketing communications and promotional offers (“Opt In“).</p>
                                <p className='qtfy_paragraph mb-2'>If following your original consent you wish, at any time, not to receive from us such marketing communications, or ask us to stop providing you with such information, you can opt-out by following the “Opt-Out” instructions available at the bottom of each such communication or by out sending us an email to info@1TX.io requesting removal of your email address from such mailing lists. Please be sure to include your full name and email address and indicate what type of communications you do not wish to receive. We will take appropriate steps to fulfill your request; however, please note that it may take a few days or longer to process your request during which you may still receive marketing communications from us.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Links to other websites</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>The Service may contain links to websites and applications operated by other third parties. While we attempt to provide links to sites that value your privacy, we cannot be responsible for the content or privacy policies of such sites. If you submit Personal Information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy statements of such websites and applications of third parties linked to the Service.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Data retention</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>We will retain Personal Information we process on behalf of our users or collect directly from our users for as long as needed to provide the Service, subject to our compliance with this Privacy Policy. We may further retain your Personal Information as necessary to comply with our legal obligations, maintain accurate accounting, financial and otheroperational records, resolve disputes, and enforce our agreements. If you decide to terminate your account with us, copies of your information may remain on back up media.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Business transfers</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>In the conduct of our business, we may sell some of our assets (as part of a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all of our assets, or similar transaction or proceeding). Information collected from users of the Service, including Personal Information, could be transferred as part of such transaction. By submitting your Personal Information through the Service, you agree that your information may be transferred to third parties under such or similar circumstances.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Updating identifiable information</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>You may access, correct or delete Personal Information you have provided us by using the tools within the Service (for example, editing your account information on the Service) or by contacting our support at infor@1tx.io Changes you make to your Personal Information on the Service take immediate effect, but data will be retained in secure storage for a limited period afterward as part of our standard data backup process.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Changes to this privacy policy</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>We may change this policy from time to time, and if we do we will post any changes on this page. If you continue to use the Service after those changes are in effect, you agree to the revised Policy. If the changes are material, we may provide more prominent notice or seek your consent to the new policy.</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>Data location</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>Your data may be transferred to, stored and processed in the United States or any other country where we maintain facilities. We abide by the U.S.-EU and the U.S. – Swiss Safe Harbor Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of data from the European Economic Area. To learn more about the Safe Harbor program, and to view our certification, please visit http://www.export.gov/safeharbor/. We will strive to ensure that adequate measures are put in place in order to protect the User’s confidentiality and rights with respect to Personal Information. You hereby provide us with your express consent to such transfer or storage of your Personal Information. If you are a resident of California, United States, or of the European Union, you have the right to send us written request and ask for a list of Personal Information categories that we may disclosed to third parties or to which we may provided your assent for third parties’ access, and the details of such third parties. To exercise such right you may contact us at: info@1tx.io</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='qtfy_paragraph_title'>How to contact us</div>
                            </div>
                            <div className='col-md-12'>
                                <p className='qtfy_paragraph mb-2'>If you have any questions or comments regarding this Privacy Policy, you can contact us by email at info@1tx.io</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPublic />
        </div>
    )
}

export default Privacy