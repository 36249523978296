import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';

export function* signup({ payload }) {
    try {
       
        yield put({ type: types.REGISTER_LODING });
        let response;
        response = yield call(api.signup, payload);
       
       
       
        if (response.status == 200) {
            yield put({ type: types.REGISTER_SUCCESS, payload: response.data.message }); 
                          
        } 
        else if(response.response.status == 500){            
            yield put({ type: types.REGISTER_FAILURE, payload: response.response.data.message }); 
        }
        else{
            throw response
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        // console.log('c err', {...err});

    }
}

export function* signupComplete({ payload }) {
    try {
       
        yield put({ type: types.REGISTER_LODING });
        let response;
        response = yield call(api.signupComplete, payload);
        
        const userInfo = response.data
        yield put({ type: types.REGISTER_SUCCESS, payload: {userInfo} });
        if (response.status == 200) {
            yield put({ type: types.REGISTER_SUCCESS, payload: {userInfo} });
        } else {
            throw response
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        // console.log('c err', {...err});

    }
}

function* signupSaga(data) {
    yield takeLatest(types.REGISTER_REQUEST, signup);
    yield takeLatest(types.SINGUP_COMPLETE_REQUEST, signupComplete);
    
}

export default signupSaga;
