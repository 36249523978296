import * as types from './types'

const defaultState = {
    
    message:'',    
    isError: false,
    isPending:false,
    isDuplicate:false,
    isRegister:false,
    
};

export default function signupReducer(state = defaultState, action) {
   
    switch (action.type) {
        case types.REGISTER_LODING:
            return {...state,isPending: true, isError: false,isDuplicate:false}
        case types.REGISTER_SUCCESS:
            return {...state,
                 message: action.payload,isRegister:true
                ,isPending: false, isError: false,isDuplicate:false };
     
        case types.REGISTER_FAILURE:
            return {...state, 
                    message:action.payload,                    
                    isPending: false, 
                    isError: true, 
                    isDuplicate:false,
                    isRegister:false
                   };
        case types.REGISTER_COMPLETE:
            return {...state,
                userInfo: {
        
                },    
                error:[],  
                isRegister:false,                 
                isPending: false, 
                isError: false, 
                isDuplicate:false
               };;
        default:
            return state;
    }

}