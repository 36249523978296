export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LODING = 'LOGIN_LODING';
export const LOGIN_PENDING_ACCOUNT = 'LOGIN_PENDING_ACCOUNT';
export const LOGIN_PROFILE_PENDING = 'LOGIN_PROFILE_PENDING'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';



export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';

