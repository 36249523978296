import axios from 'axios';

export function fetchApi(url, payload) {
  //https://dtlehta1thuw9.cloudfront.net/auth/api/auth

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_AUTH_URL + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => {
      return { ...Error }
    }
  );
}

export function getProjetManagementApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/projectmgmt

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_PROJECTMANAGEMENT + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}



export function getTescaseManagementApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/testcasemgmt

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_TESTCASEMANAGEMENT + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}



export async function getReportManagementApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/quantifyreport 

  return await axios({
    method: payload.method,
    url: process.env.REACT_APP_REPORT_URL + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}

export function getManagementApi(url,payload){
  //https://dtlehta1thuw9.cloudfront.net/management

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_MANAGEMENT + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null
  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}

export function getplatformadminApi(url,payload){
  //https://dtlehta1thuw9.cloudfront.net/management
 
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_PLATFORMADMIN + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null
  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}


export function getjmx(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/testcasemgmt
 
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_TESTCASEMANAGEMENT + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
    responseType:"text"

  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}


export function getSubscribeApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/auth/api/subscription
 
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_SUBSCRIPTION + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}


export function getProductSubscribeApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/auth/api
 
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_PRODUCTSUBSCRIPTION + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}

export function getQuantifyResultApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/quantifyresult

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_QUANTIFYRESULT + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}



export function getapiRecording(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/quantifyresult

  return axios({
    method: payload.method,
    url: url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null
    

  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}
//Test case configraiton details for Introduciton page in PDF, Word
export async function fetchTestcaseConfigurationDetails(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/auth/api
 
  return await axios({
    method: payload.method,
    url: url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}




//stoprun,startEnvironment,viewRun,createEnvironment,deleteEnvironment,viewEnvironment,fetchMaster to  getManagementApi
//getpi, fetchParamsApi to getProjetManagementApi
//fetchTestCasesApi, fetchJmxApi to getTescaseManagementApi
//getAPIReport,getReportApi , clientProfileAPI to getReportManagementApi



